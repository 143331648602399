@import 'src/utils/utils';

.promo {
  @include zero;
  position: relative;
  margin-top: 20px;
  padding-top: 30px;
  padding-right: 16px;
  padding-bottom: 45px;
  padding-left: 16px;
  color: $Main-2;
  overflow: hidden;

  @media(min-width: $md) {
    margin-right: 16px;
    margin-left: 16px;
    border-radius: 20px;
  }
  @media(min-width: $lg) {
    margin-right: 0;
    margin-left: 0;
    padding: 30px;
    border-radius: 20px;
  }
  @media(min-width: $xxl) {
    @include box;
    padding: 30px;
  }

  &__banner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: 0;
    padding: 0;
    background-color: $Main-6;
    pointer-events: none;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $t-40;
      content: ''
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  h2 {
    @include Head-35-bold;
    margin: 0;
    margin-bottom: 15px;
  }

  p {
    @include Text-16-reg;
    margin: 0;
    margin-top: 10px;
  }

  ul {
    margin: 0;
    margin-top: 10px;
    padding: 0;
    list-style: none;

    li {
      position: relative;
      display: flex;
      gap: 10px;

      &:not(:first-child) {
        margin-top: 5px;
      }

      &:before {
        content: '—';
      }
    }
  }
}